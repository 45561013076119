import './App.css';
import './App.sass';
import AOS from 'aos';
import "aos/dist/aos.css";
import React from 'react';
import {FaArrowUp, FaMapMarkerAlt, FaPhoneAlt, FaMobileAlt, FaEnvelope, FaUserAlt } from 'react-icons/fa'
import {useEffect, useState} from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Image } from 'react-bootstrap';
import withSizes from 'react-sizes';





const App = ({isMobile, isTablet}) => {


  const [isActive, setIsActive] = useState(false);
  
  const containerStyle = {
    width: isMobile ? '300px' : isTablet ? '500px' : '900px',
    height: '400px',
  };

  const [zoom, setZoom] = useState(15);
 const [center, setCenter] = useState({
    lat: 38.087387,
    lng: 23.977467,
  });

  useEffect(() => {
    AOS.init({
      easing: "ease-out",
        duration: 800,
    });
    AOS.refresh();
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA4LiZrw5epBb-8CueAnAPlDpcs1ax7UYw', // ,
    // ...otherOptions
  });

  window.onscroll = function() {
    scrollFunction();
  };
  
  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("toTop").style.display = "block";
    } else {
      document.getElementById("toTop").style.display = "none";
    }
  }

  return (
    <div className="App">

    <form action="#home">
      <button id="toTop" title="Go to top">
    <FaArrowUp/>
      </button>
    </form>

    <div className="header-wrapper" id="home">
      <section className="hero is-fullheight has-bg-img">
      <nav className="navbar " role="navigation" aria-label="main navigation" >
      <div className="navbar-brand">
      {/* <a href="" className="navbar-item-img-max-height p-5">
        <img alt="logo" src={process.env.PUBLIC_URL + '/logoHeader.png'} width="400" />
      </a> */}
      <div className="navbar-brand">
            <div onClick={() => setIsActive(!isActive)} className={isActive ? "navbar-burger burger mt-5 is-active" : "navbar-burger burger mt-5"} data-target="mobile-nav">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
    </div>

    <div id="mobile-nav" className={isActive  ? "navbar-menu is-active" : "navbar-menu"}>
            
            <div className="navbar-start pl-5 mt-4 has-text-weight-semibold">
        <a href="#resume" className="navbar-item p-5">
          ΒΙΟΓΡΑΦΙΚΟ
        </a>

        <a href="#services" className="navbar-item p-5">
          ΥΠΗΡΕΣΙΕΣ
        </a>

        <a href="#my-work" className="navbar-item p-5 ">
          ΕΞΟΠΛΙΣΜΟΣ
        </a>

        <a href="#contact" className="navbar-item p-5 ">
          ΕΠΙΚΟΙΝΩΝΙΑ
        </a>


      </div>
        
          </div>

    
    
  </nav>
        <div className="hero-body">
          <div className="container has-text-centered p-6 margin-title">
            <h2  className={isMobile ? "title is-1 has-text-weight-bold" : "title is-1 has-text-weight-bold ml-6"}>Όρχα Σιμίνα</h2>
            <h1  className={isMobile ? "subtitle is-3 profession has-text-weight-semibold" : "subtitle is-3 profession has-text-weight-semibold ml-6 pt-3"}>Χειρουργός Οφθαλμίατρος</h1>
          </div>
        </div>
      </section>
    </div>

    <div className="main-content">

      

      <div className="section-color services" id="services">
        <div className="container">
          <div className="columns is-multiline">
            <div
              className="column is-12"
              data-aos="fade-in"
              data-aos-easing="linear"
            >
              <h1 className="title has-text-centered section-title">ΙΑΤΡΙΚΕΣ ΥΠΗΡΕΣΙΕΣ</h1>

              <br />
            </div>
            <div className="columns is-12">
              <div
                className="column is-9"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                <h1 className="subtitle">Χειρουργικές Επεμβάσεις</h1>
                <hr />
                <ul className="listServices">
                  <li>Χειρουργική καταρράκτης με φακοθρυψία</li>
    <li>Ενδοϋαλοειδικές εγχειρήσεις ενέσεις</li>
    <li>Θεραπεία γλαυκώματος</li>
    <li>YAG LASER οπίσθια καψουλόρηξη-ιριδοτομή</li>
    <li>Διαθλαστικές επεμβάσεις-Laser  μυωπίας -υπερμετρωπίας -
αστιγματισμό</li>
    <li>Αισθητική βλεφαροπλαστική</li>
    <li>Μικροεπεμβάσεις: χαλάζιο, πτερύγιο</li>
  </ul>
              </div>
              <div
                className="column is-9"
                data-aos="fade-in"
                data-aos-easing="linear"
              >
                <h1 className="subtitle">Διαγνωστικές Εξετάσεις</h1>
                <hr />
                <ul className="listServices">
    <li><ul className="listServicesIn">
      <h1>Μετρήσεις: </h1>
        <li className="ml-5">
        Διάθλαση με χρήση ηλεκτρονικού φόροτερου
        </li>
        <li className="ml-5">
        Βιομικροσκόπηση με λυχνία CSO
        </li>
        <li className="ml-5">
        Βυθοσκόπηση(Άμεση έμμεση Goldman)
        </li>
      </ul></li>
    <li>OCT OPTOVUE (Οπτικό νεύρο -Ωχρά κηλίδα-Κερατοειδούς)</li>
    <li>Παχυμετρία κερατοειδούς</li>
    <li>Οπτικά πεδία</li>
  </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className="section-dark my-work" id="my-work">
        <div className="container">
          <div
            className="columns is-multiline"
            data-aos="fade-in"
            data-aos-easing="linear"
          >
            <div className="column is-12">
              <h1 className="title has-text-centered section-title">ΕΞΟΠΛΙΣΜΟΣ</h1>
            </div>
            <div className="column is-6">
            <Image
                  className=" work-item"
                  src={process.env.PUBLIC_URL + '/equipment3.jpg'}
                ></Image>
            </div>
            <div className="column is-6">
                <Image
                  className=" work-item"
                  src={process.env.PUBLIC_URL + '/equipment1.jpg'}
                ></Image>
                <Image
                  className=" work-item"
                  src={process.env.PUBLIC_URL + '/equipment2.jpg'}
                ></Image>
            </div>

            <div className="column is-12 has-text-centered">
            <Image
                  className=" work-item"
                  src={process.env.PUBLIC_URL + '/equipment4.jpg'}
                ></Image>
            </div>
            
          </div>
        </div>
      </div>

      <div className="section-light resume" id="resume">
        <div className="container">
          <div
            className="columns is-multiline"
            data-aos="fade-in"
            data-aos-easing="linear"
          >
            <div className="column is-12 about-me">
              <h1 className="title has-text-centered section-title">
                ΒΙΟΓΡΑΦΙΚΟ
              </h1>
            </div>
            <div className="column is-10 has-text-left is-offset-1">
              <h2 className="subtitle">
                <ul className="listResume" >
                  <li >
                    Γεννήθηκα στην Ρουμανία
                  </li>
                  <li >
                    Το 1997 ολοκλήρωσα τις βασικές σπουδές μου στην Ιατρική Σχολή του Iuliu Hateganu, Cluj
                  </li>
                  <li >
                    Ξεκίνησα την πρώτη περίοδο της ειδικότητας μου στο νοσοκομείο Ερυθρός Σταυρός, στο οποίο ολοκλήρωσα τον πρώτο ενάμιση χρόνο ειδίκευσης (από 16 Ιουνίου 1999 έως 13 Νοεμβρίου 2000)
                  </li>
                  <li>
                    Στη συνέχεια δούλεψα ως επιστημονικός συνεργάτης στο τμήμα Οφθαλμολογικών Φλεγμονών και Ανοσολογίας της Κρατικής Οφθαλμολογικής Κλινικής Αθηνών υπό την καθοδήγηση του Dr. Νίκου Μαρκομιχελάκη
                  </li>
                  <li>
                    Ολοκλήρωσα την ειδικότητα μου στο νοσοκομείο Γενικό Κρατικό Αθηνών από 15/11/2001 έως 17/06/2004. Το φθινόπωρο του 2004 πήρα τον τίτλο της ειδικότητας της οφθαμολογίας
                  </li>
                  <li>
                    Δημιούργησα το οφθαλμολογικό ιατρείο στην Νέα Μάκρη το 2005.
                  </li>
                </ul>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="section-color contact" id="contact">
        <div className="container">
          <div
            className="columns is-multiline"
            data-aos="fade-in-up"
            data-aos-easing="linear"
          >
            
            <div className="column is-12">
              <h1 className="title has-text-centered section-title">
                ΕΠΙΚΟΙΝΩΝΙΑ
              </h1>
            </div>
            <div className="columns">
            <div className="column is-6 is-offset-4 info">
            <h2>Στοιχεία Επικοινωνίας</h2>
            <div className="row">
                <FaUserAlt/> Όρχα Σιμίνα
              </div>
              <div className="row">
                <FaMapMarkerAlt/> Λιβυσσίου & Αγ. Κωσταντίνου, 190 05 Νέα Μάκρη
              </div>
              <div className="row">
                <FaPhoneAlt/> 2294099700
              </div>
              <div className="row">
                <FaMobileAlt/> 6944640656
              </div>
              <div className="row">
                <FaEnvelope/> papasimina@yahoo.com
              </div>
            </div>


            <div className="column is-8 is-offset-1  info">
            
              <div>
                <h2>Ωράριο Λειτουργίας</h2>
                <p><b>Δευτέρα-Τετάρτη:</b>&nbsp;&nbsp; 18:00-20:00</p>
                <p><b>Τρίτη-Πέμπτη-Παρασκευή:</b>&nbsp;&nbsp; 10:00-12:00 {' & '} 18:00-20:00</p>
                
              </div>
            </div>
            </div>
            <div className="column is-offset-2 map">{isLoaded && (
              <>
              <GoogleMap
              /* options={options} */
              className="googleMap"
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
            >
              <Marker
      position={{lat: 38.087387, lng: 23.977467 }}
    />
            </GoogleMap>
            </>
            )}</div>
            
            
          </div>
        </div>
      </div>

      <div className="footer">
        
      <p>
      © 2021 Όρχα Σιμίνα
      </p>
    </div>

</div>

</div>
  )
  
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
  isTablet: width < 1250,
});

export default React.memo(withSizes(mapSizesToProps)(App));
